import React from "react";
import FormInput from "../form-input/FormInput";
import { Box, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import {messageInfo, getExactYearToShowCalculationOf} from "../../utils/formulae"

function Inputs({ handleSalaryChange, handleDateChange, values, month }) {

  const budgetYear = getExactYearToShowCalculationOf();
  const message = messageInfo(values.salary, month,budgetYear);
  const textColor = useColorModeValue("light.text.600", "dark.text.200");
  return (
    <Box py="60px" w={["100%","80%","70%"]} flex={"1"} color={textColor}>
      <Flex
        w={"100%"}
        flexDirection="column"
        justifyContent="center"
        gap={6}
      >
        {/* date */}
        <FormInput
          handleChange={handleDateChange}
          label="Enter the starting date of your current salary"
          type="date"
          required
          value={values.date}
        />
        <FormInput
          handleChange={handleSalaryChange}
          label="Enter your Current monthly salary (PKR)"
          mb={6}
          type="text"
          maxLength="10"
          required
          value={values.salary}
        />
      </Flex>
      {/* message */}
      {message ? <Text color="light.text.300">{message}</Text> : ""}
    </Box>
  );
}

export default Inputs;
