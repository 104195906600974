// This file is for formatting numbers
export const formatNumber = (value) => {
  if (isNaN(value)) {
    return value;
  }
  return new Intl.NumberFormat("en-PK", {
    maximumSignificantDigits: 10,
  }).format(value);
};

export default formatNumber;
