import {
  monthlySalaryTax,
  monthlyNetSalaryAfterTax,
  grossAnnualSalary,
  annualSalaryTax,
  annualNetSalaryAfterTax,
  getExactYearToShowCalculationOf,
} from "../../utils/formulae.js";
import formatNumber from "../../utils/format.js";
import { Box, Text, Flex, useColorModeValue } from "@chakra-ui/react";
import CustomTooltip from "../tooltip/Tooltip.jsx";
import {budgetMonth} from "../../utils/config"

function Result({ salary, date }) {
  const textColor = useColorModeValue("light.text.600", "dark.text.200");

  //extract month from the date
  let month = date.split("-")[1];
  // get budget year
  const budgetYear = getExactYearToShowCalculationOf();
  const minDate = new Date(
    `${budgetYear - 1}-${budgetMonth.monthNumber}-${new Date(
      budgetYear - 1,
      budgetMonth.monthNumber,
      0
    ).getDate()}`
  );
  const maxDate = new Date(`${budgetYear}-${budgetMonth.monthNumber + 1}-01`);
  const currentDate = new Date(date);
  // if date does not falls between this fiscal year, then we need to calculate for 12 months
  if (currentDate < minDate || currentDate > maxDate) {
    // 7 because the budget month is 6 and when this month will go into getExactMonthTillBudgetMonth() then it will return 12 months, which
    // is what we want
    month = 7;
  }

  // extracting values from functions
  const monthlySalaryTaxValue = monthlySalaryTax(salary, month, budgetYear);
  const monthlyNetSalaryAfterTaxValue = monthlyNetSalaryAfterTax(
    salary,
    month,
    budgetYear
  );
  const grossAnnualSalaryValue = grossAnnualSalary(salary, month);

  const annualSalaryTaxValue = annualSalaryTax(salary, month, budgetYear);

  const annualNetSalaryAfterTaxValue = annualNetSalaryAfterTax(
    salary,
    month,
    budgetYear
  );

  return (
    <Box flex="2" w={["100%", "80%"]} color={textColor}>
      <Flex
        flexDirection="column"
        alignItems={["center", "center", "flex-start", "flex-start"]}
      >
        <Flex justifyContent="space-between" w="100%">
          <Text
            mb={4}
            
            textTransform="uppercase"
            fontSize={["sm", "xl"]}
          >
            <CustomTooltip
              placement="top"
              label="Monthly salary before tax deductions"
            />
            &nbsp;Monthly Gross Salary:
          </Text>
          <Text
            fontSize={["sm", "xl"]}
            color={textColor}
            className="tax-values"
          >
            {salary && date ? `PKR ${formatNumber(salary)}` : `PKR 0`}
          </Text>
        </Flex>
        <Flex justifyContent="space-between" w="100%">
          <Text
            mb={4}
            
            textTransform="uppercase"
            fontSize={["sm", "xl"]}
          >
            <CustomTooltip label="Cumulative tax deducted from monthly salary at the end of a month" />
            &nbsp;Tax on salary per month:
          </Text>
          <Text
            fontSize={["sm", "xl"]}
            color="red.500"
            className="tax-values"
          >
            {salary && date
              ? `PKR ${formatNumber(monthlySalaryTaxValue)}`
              : `PKR 0`}
          </Text>
        </Flex>
        <Flex justifyContent="space-between" w="100%">
          <Text
            mb={4}
            
            textTransform="uppercase"
            fontSize={["sm", "xl"]}
          >
            <CustomTooltip label="Salary after tax deductions from monthly gross salary" />
            &nbsp;monthly Net salary after tax:
          </Text>
          <Text
            fontSize={["sm", "xl"]}
            color={textColor}
            className="tax-values"
          >
            {salary && date
              ? `PKR ${formatNumber(monthlyNetSalaryAfterTaxValue)}`
              : `PKR 0`}
          </Text>
        </Flex>
        <Flex justifyContent="space-between" w="100%">
          <Text
            mb={4}
            
            textTransform="uppercase"
            fontSize={["sm", "xl"]}
          >
            <CustomTooltip
              label="Yearly salary for a given fiscal year before taxes"
              fontSize="sm"
            />
            &nbsp;annual Gross salary:
          </Text>
          <Text
            fontSize={["sm", "xl"]}
            color={textColor}
            className="tax-values"
          >
            {salary && date
              ? `PKR ${formatNumber(grossAnnualSalaryValue)}`
              : `PKR 0`}
          </Text>
        </Flex>
        <Flex justifyContent="space-between" w="100%">
          <Text
            mb={4}
            
            textTransform="uppercase"
            fontSize={["sm", "xl"]}
          >
            <CustomTooltip label="Cumulative tax deductible from annual salary at the end of that fiscal year" />
            &nbsp;Annual tax on salary:
          </Text>
          <Text
            fontSize={["sm", "xl"]}
            color="red.500"
            className="tax-values"
          >
            {salary && date
              ? `PKR ${formatNumber(annualSalaryTaxValue)}`
              : `PKR 0`}
          </Text>
        </Flex>
        <Flex justifyContent="space-between" w="100%">
          <Text
            mb={4}
            
            textTransform="uppercase"
            fontSize={["sm", "xl"]}
          >
            <CustomTooltip label="Salary after all tax deductions from annual gross salary for a given fiscal year" />
            &nbsp;annual Net salary after tax:
          </Text>
          <Text
            fontSize={["sm", "xl"]}
            color={textColor}
            className="tax-values"
          >
            {salary && date
              ? `PKR ${formatNumber(annualNetSalaryAfterTaxValue)}`
              : `PKR 0`}
          </Text>
        </Flex>
      </Flex>
    </Box>
  );
}

export default Result;
