import { Tooltip } from "@chakra-ui/react";
import { faCircleQuestion } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function CustomTooltip({label}) {
  return (
    <Tooltip
      placement="top"
      label={label}
      fontSize="sm"
    >
      <span>
        <FontAwesomeIcon icon={faCircleQuestion} color="#bbb" size="xs" />
      </span>
    </Tooltip>
  );
}

export default CustomTooltip;
