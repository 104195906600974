import TaxSlab from "./components/tax-slab/TaxSlab";
import Result from "./components/result/Result";
import { useState } from "react";
import Navbar from "./components/navbar/Navbar";
import Inputs from "./components/inputs/Inputs";
import Footer from "./components/footer/Footer";
import { budgetMonth } from "./utils/config";
import {
  Box,
  useColorModeValue,
  Flex,
  Center,
  Heading,
} from "@chakra-ui/react";
import { getExactYearToShowCalculationOf } from "./utils/formulae";
import Policies from "./components/policies/Policies";

function App() {
  const handleSalaryChange = (event) => {
    setValues({
      ...values,
      salary: Number(event.target.value) ? event.target.value : "",
    });
  };

  const handleDateChange = (event) => {
    setValues({ ...values, date: event.target.value });
  };

  // hooks
  const [values, setValues] = useState({
    salary: "",
    date: "",
  });

  // ============== setting the month ===================
  //extract month from the date
  const budgetYear = getExactYearToShowCalculationOf();
  let month = values.date.split("-")[1];
  // get budget year
  const minDate = new Date(
    `${budgetYear - 1}-${budgetMonth.monthNumber}-${new Date(
      budgetYear - 1,
      budgetMonth.monthNumber,
      0
    ).getDate()}`
  );
  const maxDate = new Date(`${budgetYear}-${budgetMonth.monthNumber + 1}-01`);
  const currentDate = new Date(values.date);
  // if date does not falls between this fiscal year, then we need to calculate for 12 months
  if (currentDate < minDate || currentDate > maxDate) {
    // 7 because the budget month is 6 and when this month will go into getExactMonthTillBudgetMonth() then it will return 12 months, which
    // is what we want
    month = budgetMonth.monthNumber + 1;
  }

  // dark mode hook
  const mainSectionBg = useColorModeValue("light.bg.200", "dark.bg.600");

  return (
    <Box w="100%">
      {/* navbar */}
      <Navbar />
      {/* max width limit */}
      <Box>
        {/* heading */}
          <Heading
            textAlign="center"
            textTransform="uppercase"
            fontWeight="500"
            pt={[10, 5, 4]}
            bg={mainSectionBg}
            as="h1"
            size={["sm", "md", "md", "lg"]}
          >
            {`Salary Income Tax Calculator for FY ${
              budgetYear - 1
            }-${budgetYear}`}
          </Heading>
        <Box maxW="1270px">
        </Box>
        {/* Inputs and results */}
        <Box
          w="100%"
          px={10}
          bg={mainSectionBg}
          pb={["60px", "60px", "60px", "60px"]}
          pt={["0", "0", "0", "60px"]}
        >
          <Center>
            <Flex
              w={["100%", "100%", "1270px"]}
              left=""
              flexDirection={["column", "column", "column", "row"]}
              alignItems={["center"]}
              gap={[0, 0, 0, 20]}
            >
              <Inputs
                handleSalaryChange={handleSalaryChange}
                handleDateChange={handleDateChange}
                values={values}
                month={month}
              />
              <Result salary={values.salary} date={values.date} />
            </Flex>
          </Center>
        </Box>
        {/* Tax slabs */}
        <TaxSlab date={values.date} />
        {/* Policies */}
        <Policies />
        {/* Footer */}
      </Box>
      <Footer />
    </Box>
  );
}

export default App;
