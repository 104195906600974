import React from "react";
import { Box, Heading, OrderedList, ListItem, Center, useColorModeValue, UnorderedList, Text } from "@chakra-ui/react";

function Policies() {
    const sectionBg = useColorModeValue("light.bg.200", "dark.bg.600");
    const headingColor = useColorModeValue("light.heading.900", "dark.heading.200");
    const textColor = useColorModeValue("light.text.600", "dark.text.200");
  return (
    <Center bg={sectionBg}>
      <Box w={["100%","100%","80%"]} py="100px" px={10} textAlign={["justify","justify", "left"]} color={textColor}>
        <Heading color={headingColor} textTransform="uppercase" fontWeight="500" as="h2" size="lg" mb={8} textAlign={["center", "center", "left"]}>
          Tax Calculator Terms of Service and Disclaimer
        </Heading>
        <Box>
          <Heading textTransform="uppercase" fontWeight="500" as="h3" size="md" mb={4} textAlign={["center", "center", "left"]}>
            Calculation Basis:
          </Heading>
          <OrderedList mb={4}>
            <ListItem mb={2} fontSize="18px">
              Annual salary is calculated on the basis of your current salary
              start month. Maximum applicable months for annual salary will not
              exceed 12 months for given Fiscal Year.
            </ListItem>
            <ListItem mb={2} fontSize="18px">
              Applicable taxes are calculated on the assumption that the only
              source of income is employment income and entire income is subject
              to taxes.
            </ListItem>
          </OrderedList>
        </Box>
        <Box>
          <Heading textTransform="uppercase" fontWeight="500" as="h3" size="md" mb={4} textAlign={["center", "center", "left"]}>
            Disclaimer:
          </Heading>
          <OrderedList mb={4}>
            <ListItem mb={2} fontSize="18px">
              Tax calculation is calculated for reference only and should not be
              used for any legal/financial/taxation purpose without the guidance
              of your consultant.
            </ListItem>
            <ListItem mb={2} fontSize="18px">
              For tax filing purpose, consult your tax consultant for proper
              filling.
            </ListItem>
            <ListItem mb={2} fontSize="18px">
              While we have ensured quality and accuracy of calculation but we
              bear no liability for any financial/non-financial loss incurring
              from any arithmetical mistakes/errors.
            </ListItem>
            <ListItem mb={2} fontSize="18px">
              We don’t store your salary information.
            </ListItem>
            <ListItem mb={2} fontSize="18px">
              We keep track of visitors visiting the website and do not save any
              invasive personal information.
            </ListItem>
          </OrderedList>
        </Box>
        <Box>
          <Heading textTransform="uppercase" fontWeight="500" as="h3" size="md" mb={4} textAlign={["center", "center", "left"]}>
            Governing law:
          </Heading>
          <OrderedList mb={4}>
            <ListItem mb={2} fontSize="18px">
              The tax calculation rules and all applicable laws are based on the
              governing laws and regulation of Pakistan.
            </ListItem>
          </OrderedList>
        </Box>
        <Box>
          <Heading textTransform="uppercase" fontWeight="500" as="h3" size="md" mb={4} textAlign={["center", "center", "left"]}>
            Additional Employment Cost – EOBI:
          </Heading>
          <Text fontWeight="400" size="md" mb={4} textAlign={["justify", "center", "left"]}>
            EOBI (Employee’s Old Age Benefit Institution) is a statutory payment to be 
            collected by the government via employers for all employees with a 
            confirmed employment status with their employer. Calculation basis are as 
            follows:
          </Text>
          <OrderedList mb={4}>
            <ListItem mb={2} fontSize="18px">
              Contribution calculation basis:
              <UnorderedList mb={4}>
              <ListItem mb={2} fontSize="18px">
                Employee: 1% of minimum wage
              </ListItem>
              <ListItem mb={2} fontSize="18px">
                Employer 5% of minimum wage
              </ListItem>
              </UnorderedList>
            </ListItem>
            <ListItem mb={2} fontSize="18px">
              Definition of Minimum wage by EOBI: Rs. 20,000 per month
            </ListItem>
            <ListItem mb={2} fontSize="18px">
              Contribution Value:
              <UnorderedList mb={4}>
              <ListItem mb={2} fontSize="18px">
                Employee contribution: Rs. 250 per month
              </ListItem>
              <ListItem mb={2} fontSize="18px">
                Employer contribution: Rs. 1,250 per month
              </ListItem>
              </UnorderedList>
            </ListItem>
          </OrderedList>
        </Box>
      </Box>
    </Center>
  );
}

export default Policies;
