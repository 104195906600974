import React from "react";
import { Box, Flex, Text, Link, useColorModeValue } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faInstagramSquare,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

function Footer() {
  const sectionBg = useColorModeValue("light.bg.900", "dark.bg.900");
  const iconColor = useColorModeValue("light.icon.700", "dark.icon.300");
  return (
    <Box bg={sectionBg}>
      <Box py={8}>
        <Text textAlign="center" >
          Made with <FontAwesomeIcon icon={faHeart} color="#ff2400" size="sm" /> by{" "}
          <Link textDecoration="underline" href="https://www.emerald-labs.com/">
            Emerald Labs
          </Link>{" "}
          for Public Awareness
        </Text>
        <Flex
          className="font"
          color="gray.900"
          justifyContent="center"
          alignItems="center"
          mt={4}
          gap={5}
        >
          <Link
            href="https://www.facebook.com/EmeraldLabsPk/?ref=pages_you_manage"
            target="_blank"
          >
            <Text color={iconColor}>
              <FontAwesomeIcon icon={faFacebook} size="lg" />
            </Text>
          </Link>
          <Link href="https://www.instagram.com/emeraldlabspk/" target="_blank">
            <Text color={iconColor}>
              <FontAwesomeIcon icon={faInstagramSquare} size="lg" />
            </Text>
          </Link>
          <Link
            href="https://www.linkedin.com/company/emeraldlabspk/mycompany/"
            target="_blank"
          >
            <Text color={iconColor}>
              <FontAwesomeIcon icon={faLinkedin} size="lg" />
            </Text>
          </Link>
        </Flex>
      </Box>
    </Box>
  );
}

export default Footer;
