import React from "react";
import { ReactComponent as Logo } from "../../assets/logo.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoon, faSun } from "@fortawesome/free-solid-svg-icons";
import {
  useColorMode,
  Button,
  Flex,
  Spacer,
  Center,
  Box,
  Link,
  useColorModeValue,
} from "@chakra-ui/react";
// color switcher

function Navbar() {
  const navBg = useColorModeValue("light.bg.200", "dark.bg.600");
  const { colorMode, toggleColorMode } = useColorMode();
  return (
    <Box w="100%" bg={navBg} py={5} px={[5, 10, 19]}>
      <Flex justifyContent="flex-start" className="try">
        <Box w="130px">
          <Link href="https://www.emerald-labs.com/" target="_blank">
            <Logo />
          </Link>
        </Box>
        <Spacer />
        <Center>
          <Button borderRadius="50%" w={15} onClick={toggleColorMode}>
            {colorMode === "light" ? (
              <FontAwesomeIcon icon={faMoon} size="xl" color="#64caa9cf" />
            ) : (
              <FontAwesomeIcon icon={faSun} size="xl" color="#64caa9cf" />
            )}
          </Button>
        </Center>
      </Flex>
    </Box>
  );
}

export default Navbar;
