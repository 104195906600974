export const budgetMonth = {
    "monthNumber": 6
}

export const config = {
  // These values are up till the year
  2022: [
    {
      "0-600000": {
        minTax: 0,
        taxOnExcessPercentage: 0,
      },
    },
    {
      "600001-1200000": {
        minTax: 0,
        taxOnExcessPercentage: 0.05,
      },
    },
    {
      "1200001-1800000": {
        minTax: 30000,
        taxOnExcessPercentage: 0.1,
      },
    },
    {
      "1800001-2500000": {
        minTax: 90000,
        taxOnExcessPercentage: 0.15,
      },
    },
    {
      "2500001-3500000": {
        minTax: 195000,
        taxOnExcessPercentage: 0.175,
      },
    },
    {
      "3500001-5000000": {
        minTax: 370000,
        taxOnExcessPercentage: 0.2,
      },
    },
    {
      "5000001-8000000": {
        minTax: 670000,
        taxOnExcessPercentage: 0.225,
      },
    },
    {
      "8000001-12000000": {
        minTax: 1345000,
        taxOnExcessPercentage: 0.25,
      },
    },
    {
      "12000001-30000000": {
        minTax: 2345000,
        taxOnExcessPercentage: 0.275,
      },
    },
    {
      "30000001-50000000": {
        minTax: 7295000,
        taxOnExcessPercentage: 0.3,
      },
    },
    {
      "50000001-75000000": {
        minTax: 13295000,
        taxOnExcessPercentage: 0.325,
      },
    },
    {
      "75000001-and above": {
        minTax: 21420000,
        taxOnExcessPercentage: 0.35,
      },
    },
  ],
  2023: [
    {
      "0-600000": {
        minTax: 0,
        taxOnExcessPercentage: 0,
      },
    },
    {
      "600001-1200000": {
        minTax: 0,
        taxOnExcessPercentage: 0.025,
      },
    },
    {
      "1200001-2400000": {
        minTax: 15000,
        taxOnExcessPercentage: 0.125,
      },
    },
    {
      "2400001-3600000": {
        minTax: 165000,
        taxOnExcessPercentage: 0.2,
      },
    },
    {
      "3600001-6000000": {
        minTax: 405000,
        taxOnExcessPercentage: 0.25,
      },
    },
    {
      "6000001-12000000": {
        minTax: 1005000,
        taxOnExcessPercentage: 0.325,
      },
    },
    {
      "12000001-and above": {
        minTax: 2955000,
        taxOnExcessPercentage: 0.35,
      },
    },
  ],
  2024: [
    {
      "0-600000": {
        minTax: 0,
        taxOnExcessPercentage: 0,
      },
    },
    {
      "600001-1200000": {
        minTax: 0,
        taxOnExcessPercentage: 0.025,
      },
    },
    {
      "1200001-2400000": {
        minTax: 15000,
        taxOnExcessPercentage: 0.125,
      },
    },
    {
      "2400001-3600000": {
        minTax: 165000,
        taxOnExcessPercentage: 0.225,
      },
    },
    {
      "3600001-6000000": {
        minTax: 435000,
        taxOnExcessPercentage: 0.275,
      },
    },
    {
      "6000001-and above": {
        minTax: 1095000,
        taxOnExcessPercentage: 0.35,
      },
    },
  ],
};
