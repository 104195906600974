import { Input, FormControl, FormLabel, useColorModeValue } from "@chakra-ui/react";

function FormInput({ handleChange, label, ...otherInputProps }) {
  const fontColor = useColorModeValue("light.text.600", "dark.text.200");
  return (
    <FormControl w={"100%"} isRequired>
      <FormLabel textTransform="uppercase" fontWeight="normal" fontSize={["10px","11px"]}>{label}</FormLabel>
      <Input
        color={fontColor}
        focusBorderColor="light.bg.900"
        w={["100%" ,"100%"]}
        textAlign={["center", "left"]}
        fontSize={20}
        onChange={handleChange}
        {...otherInputProps}
      />
    </FormControl>
  );
}

export default FormInput;
