import React from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  useColorModeValue,
} from "@chakra-ui/react";
import formatNumber from "../../utils/format";

function TaxSlabTable({ config, exactYearBudget }) {
  const tableHeadingColor = useColorModeValue("light.heading.900", "light.heading.200");
  const borderColor = useColorModeValue("light.border.700", "dark.border.900");
  return (
    <>
      {config[`${exactYearBudget}`] && (
        <Box>
          <Table>
            <Thead>
              <Tr textTransform="uppercase">
                <Th
                  fontWeight="500"
                  color={tableHeadingColor}
                  textAlign="center"
                  fontSize={[13, 16]}
                  borderColor={borderColor}
                >
                  Min Salary brackets - Max Salary brackets
                </Th>
                <Th
                  fontWeight="500"
                  color={tableHeadingColor}
                  textAlign="center"
                  fontSize={[13, 16]}
                  borderColor={borderColor}
                >
                  Minimum amount of tax
                </Th>
                <Th
                  fontWeight="500"
                  color={tableHeadingColor}
                  textAlign="center"
                  fontSize={[13, 16]}
                  borderColor={borderColor}
                >
                  Tax percentage(%)
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {config[`${exactYearBudget}`].map((bracket, index) => {
                let keys = Object.keys(bracket)[0];
                let keyValues = keys.split("-");
                // console.log(`${keyValues[0]}-${keyValues[1]}`);
                return (
                  <Tr key={index} textTransform="uppercase" fontSize={[13, 16]}>
                    <Td textAlign="center" borderColor={borderColor}>{`${formatNumber(
                      keyValues[0]
                    )}-${formatNumber(keyValues[1])}`}</Td>
                    <Td textAlign="center" borderColor={borderColor}>{`${formatNumber(
                      bracket[`${keys}`].minTax
                    )}`}</Td>
                    <Td textAlign="center" borderColor={borderColor}>
                      {/* if the number is integer show as it is, otherwise show it to 1 decimal place */}
                      {(bracket[`${keys}`].taxOnExcessPercentage * 100).toFixed(1)}
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Box>
      )}
    </>
  );
}

export default TaxSlabTable;
