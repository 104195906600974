import React from "react";
import ReactDOM from "react-dom/client";
import { ChakraProvider, extendTheme, ColorModeScript } from "@chakra-ui/react";
import App from "./App";

const config = {
  initialColorMode: "dark",
  useSystemColorMode: true,
};

const colors = {
  red:{
    500: "#ff2400"
  },

  light:{
    bg:{
      900: "#64caa987",
      200: "#eef9f88c"
    },
    border:{
      700: "#d8e0e8"
    },
    heading:{
      900: "#3c4655"
    },
    text:{
      600: "#3c4655",
      500: "#777",
      300: "#A0AEC0",
    },
    icon:{
      700: "#3C4655"
    },
    input:{
      500: "#777"
    }
  },

  dark: {
    bg: {
      900: "#0D0D0B",
      600: "#171C25"
    },
    border:{
      900: "#5a5454",
    },
    heading:{
      200: "#ffffffeb"
    },
    text:{
      200: "#cfcacaeb"
    },
    icon:{
      300: "#ffffffcc"
    },
    input:{
      200: "#ffffffeb"
    }
  }
};

const theme = extendTheme({ colors, config });

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <App />
    </ChakraProvider>
  </React.StrictMode>
);
