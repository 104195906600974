import { config } from "../../utils/config";
import { getExactYearToShowCalculationOf } from "../../utils/formulae";
import {
  Heading,
  Box,
  Tabs,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  useColorModeValue,
} from "@chakra-ui/react";
import TaxSlabTable from "../tax-slab-table/TaxSlabTable";

function TaxSlab() {
  const sectionBg = useColorModeValue("light.bg.900", "dark.bg.900");
  const headingColor = useColorModeValue(
    "light.heading.900",
    "dark.heading.200"
  );
  const textColor = useColorModeValue("light.text.600", "dark.text.200");
  //get the exact current year for which the budget to show
  const exactYearBudget = getExactYearToShowCalculationOf();
  const nextExactYearBudget = getExactYearToShowCalculationOf() - 1;
  const tillTwentyTwoYearBudget = getExactYearToShowCalculationOf() - 2;

  return (
    <Box
      bg={sectionBg}
      py="60px"
      px={[0, 10]}
      color={textColor}
      overflowX="auto"
    >
      <Heading
        as="h3"
        color={headingColor}
        fontSize={["20px", "26px"]}
        fontWeight="500"
        textAlign={["center", "center"]}
        textTransform="uppercase"
        size="lg"
        mb={6}
      >
        Tax Slab for
      </Heading>
      <span>
        <Tabs
          variant="soft-rounded"
          colorScheme="green"
          align="center"
          defaultIndex={2}
        >
          <TabList py={4}>
            <Tab _selected={{ bg: "white" }}>{`FY ${
              tillTwentyTwoYearBudget - 1
            }-${tillTwentyTwoYearBudget}`}</Tab>
            <Tab _selected={{ bg: "white" }}>{`FY ${
              nextExactYearBudget - 1
            }-${nextExactYearBudget}`}</Tab>
            <Tab _selected={{ bg: "white" }}>{`FY ${
              exactYearBudget - 1
            }-${exactYearBudget}`}</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <TaxSlabTable
                exactYearBudget={tillTwentyTwoYearBudget}
                config={config}
              />
            </TabPanel>
            <TabPanel>
              <TaxSlabTable
                exactYearBudget={nextExactYearBudget}
                config={config}
              />
            </TabPanel>
            <TabPanel>
              <TaxSlabTable exactYearBudget={exactYearBudget} config={config} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </span>
    </Box>
  );
}

export default TaxSlab;
