import { config, budgetMonth } from "./config";
import {formatNumber} from "./format"

// this function calculates that which year of budget we should show the user
export const getExactYearToShowCalculationOf = () => {
  //get current month
  const currentMonth = new Date().getMonth() + 1;

  //get current year
  let currentYear = new Date().getFullYear();
  // if the month is greater than the budget month, show next year's calculation

  let exactMonth = getExactMonthTillBudgetMonth(currentMonth);
  if (exactMonth > budgetMonth.monthNumber) {
    return currentYear + 1;
  } else {
    return currentYear;
  }
};

// this funtion takes salary and year and budget year, figures out the tax slab and returns the minTax, tax% and lowerLimit of the slab
const getTaxPercentage = (salary, yearOfBudget) => {
  let minTax = 0;
  let taxPercentage = 0;
  let lowerLimit = 0;
  let upperLimit = 0;

  try {
    for (let i = 0; i < config[`${yearOfBudget}`].length; i++) {
      let salaryRange = Object.keys(config[`${yearOfBudget}`][i]);
      for (let j = 0; j < salaryRange.length; j++) {
        //   brackets in array from, so we can extract the upper and lower limit easily
        let range = salaryRange[j].split("-");

        // handling the case for last limit, it doesn't have all the numbers so need a seperate condition
        if (salary > range[0] && isNaN(salary[1])) {
          minTax = config[`${yearOfBudget}`][i][salaryRange[j]]["minTax"];
          taxPercentage =
            config[`${yearOfBudget}`][i][salaryRange[j]][
              "taxOnExcessPercentage"
            ];
          lowerLimit = parseInt(range[0]);
          upperLimit = isNaN(range[1]) ? range[1] : parseInt(range[1]);
          break;
        } else {
          if (salary > range[0] && salary <= range[1]) {
            minTax = config[`${yearOfBudget}`][i][salaryRange[j]]["minTax"];
            taxPercentage =
              config[`${yearOfBudget}`][i][salaryRange[j]][
                "taxOnExcessPercentage"
              ];

            lowerLimit = parseInt(range[0]);
            // need to handle the case where the upperLimit is "up"
            upperLimit = range[1];
            break;
          }
        }
      }
    }
    return [minTax, taxPercentage, lowerLimit, upperLimit];
  } catch (err) {
    console.log(err);
  }
};

// this function calculates the number of months from the user entered month till the budget month
const getExactMonthTillBudgetMonth = (month) => {
  let totalMonths = 12;

  if (month > 0 && month <= budgetMonth.monthNumber) {
    return totalMonths - month - budgetMonth.monthNumber + 1;
  } else {
    return totalMonths - month + budgetMonth.monthNumber + 1;
  }
};

// this function takes salary and month and returns the actual annual salary of the user
export const grossAnnualSalary = (salary, month) => {
  // if both input fields have value
  if (salary && month) {
    let totalMonths = getExactMonthTillBudgetMonth(month);
    return Math.round(salary * totalMonths);
  }
};

// this function takes salary,month & budgetYear and returns the tax on user's salary for the year
export const annualSalaryTax = (salary, month, budgetYear) => {
  if (salary && month) {
    let grossAnnualSalaryValue = grossAnnualSalary(salary, month);
    let [minTax, taxPerc, lowerLimit] = getTaxPercentage(
      grossAnnualSalaryValue,
      budgetYear
    );

    return Math.round((grossAnnualSalaryValue - lowerLimit) * taxPerc + minTax);
  }
  return 0;
};

// this function takes salary,month & budgetYear and returns the net annual salary, after deducting the tax of the user for the year
export const annualNetSalaryAfterTax = (salary, month, budgetYear) => {
  if (salary && month) {
    let annualSalaryTaxValue = annualSalaryTax(salary, month, budgetYear);
    let grossAnnualSalaryValue = grossAnnualSalary(salary, month);

    return Math.round(grossAnnualSalaryValue - annualSalaryTaxValue);
  }
};

// this function takes salary,month & budgetYear and returns the monthly tax of the user on his salary
export const monthlySalaryTax = (salary, month, budgetYear) => {
  if (salary && month) {
    let totalMonths = getExactMonthTillBudgetMonth(month);
    let annualSalaryTaxValue = annualSalaryTax(salary, month, budgetYear);
    // handling division by 0
    try {
      return Math.round(annualSalaryTaxValue / totalMonths);
    } catch (err) {
      console.log(err);
    }
  }
};

// this function takes salary,month & budgetYear and returns the monthly net salary of the user, after deducting the tax
export const monthlyNetSalaryAfterTax = (salary, month, budgetYear) => {
  if (salary && month) {
    let monthlyTax = monthlySalaryTax(salary, month, budgetYear);

    return Math.round(salary - monthlyTax);
  }
};

// this function calculates the difference of two values in percentage
export const calculateDiffInPercentage = (newNumber, originalNumber) => {
  if (originalNumber === 0) return 0;
  const percentage = (
    ((newNumber - originalNumber) / originalNumber) *
    100
  ).toFixed(1);
  if (Number(percentage) === 0) return 0;
  return percentage;
};

// message function
export const messageInfo = (salary, month, yearOfBudget) => {
  if (salary && yearOfBudget) {

    let grossAnnualSalaryValue = grossAnnualSalary(salary, month);
    let [minTax,
      taxPerc,
      lowerLimit,
      upperLimit] = getTaxPercentage(grossAnnualSalaryValue, yearOfBudget);
      // console.log(lowerLimit, upperLimit, minTax, taxPerc)
    const infoMessage = `Your salary bracket is ${formatNumber(lowerLimit)}-${formatNumber(upperLimit)} with minimum tax of ${formatNumber(minTax)} and ${
      Number.isInteger(taxPerc * 100) ? taxPerc * 100 : (taxPerc * 100).toFixed(1) 
    }% tax on amount exceeding ${formatNumber(lowerLimit)}`;

    return infoMessage;
  }
};
